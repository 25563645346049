<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">企業情報修正</h3>
      <div class="uk-container uk-form-label uk-margin">
        <div
          class="
            uk-width-1-1 uk-text-center uk-padding uk-padding-remove-vertical
          "
        >
          <span class="uk-text-danger">※</span>
          は必須項目です
        </div>
      </div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-container">
          <dl class="uk-width-1-1">
            <dt class="uk-form-label">会社名&nbsp;<sup>※</sup></dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_name"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">会社名フリガナ&nbsp;<sup>※</sup></dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_kana"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">代表者氏名</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="representative_name"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">郵便番号</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_zip"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">住所</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_address"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">電話番号</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_tel"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">メールアドレス<br />(ログイン時)</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="com_email"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">担当部署&nbsp;<sup>※</sup></dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="tantou_busyo"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">担当者氏名&nbsp;<sup>※</sup></dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="tantou_name"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">担当者連絡先</dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="tantou_tel"
                class="uk-input uk-form-small"
              />
            </dd>
            <dt class="uk-form-label">担当者email&nbsp;<sup>※</sup></dt>
            <dd>
              <input
                type="text"
                name=""
                v-model="tantou_email"
                class="uk-input uk-form-small"
              />
            </dd>
          </dl>
        </div>

        <div class="uk-container uk-flex uk-flex-center uk-width-1-1">
          <div class="uk-width-1-2 uk-padding">
            <button
              type="button"
              name="button"
              class="uk-button uk-button-default uk-width-1-1"
              @click="$router.back()"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
          <div class="uk-width-1-2 uk-padding">
            <button
              type="button"
              name="button"
              class="uk-button uk-button-primary uk-width-1-1 mal10"
              @click="update_info()"
            >
              次へ&nbsp;&gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      com_name: "",
      com_kana: "",
      representative_name: "",
      com_zip: "",
      com_address: "",
      com_tel: "",
      com_email: "",
      tantou_busyo: "",
      tantou_name: "",
      tantou_email: "",
      tantou_tel: "",
      do_something: true,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "企業情報";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.get_info_company();
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    get_info_company() {
      this.$axios
        .post("/client/check_info_company", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            //eslint-disable-line
            if (response.data.flg) {
              var tmp;
              tmp = response.data;
              this.com_name = tmp["com_name"];
              this.com_kana = tmp["com_kana"];
              this.representative_name = tmp["representative_name"];
              this.com_zip = tmp["com_zip"];
              this.com_address = tmp["com_address"];
              this.com_tel = tmp["com_tel"];
              this.com_email = tmp["com_email"];
              this.tantou_busyo = tmp["tantou_busyo"];
              this.tantou_name = tmp["tantou_name"];
              this.tantou_email = tmp["tantou_email"];
              this.tantou_tel = tmp["tantou_tel"];
            } else {
              this.flg = false;
              alert(
                "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
              );
              this.$router.replace("/error");
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    update_info() {
      if (!this.do_something) return;
      this.do_something = false;
      var flg = false;
      if (
        this.com_name.length > 0 &&
        this.tantou_busyo.length > 0 &&
        this.tantou_name.length > 0 &&
        this.com_kana.length > 0
      ) {
        flg = true;
      } else {
        alert("必須項目を入力してください");
        this.do_something = true;
      }
      if (flg) {
        this.$axios
          .post("/client/update_info_company", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            com_name: this.com_name,
            com_kana: this.com_kana,
            representative_name: this.representative_name,
            com_zip: this.com_zip,
            com_address: this.com_address,
            com_tel: this.com_tel,
            com_email: this.com_email,
            tantou_busyo: this.tantou_busyo,
            tantou_name: this.tantou_name,
            tantou_email: this.tantou_email,
            tantou_tel: this.tantou_tel,
          })
          .then(
            function (response) {
              //eslint-disable-line
              if (response.data.flg) {
                console.log(response.data);
                this.$store.state.email = this.com_email;
                sessionStorage.setItem("email_c", this.$store.state.email);
                this.do_something = true;
                this.$router.push("/client-info_busyo");
              } else {
                alert(
                  "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
                );
                this.do_something = true;
                this.$router.push("/error");
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
              this.do_something = true;
            }.bind(this)
          );
      }
    },
  },
};
</script>

<style scoped>
dl dt {
  width: 165px;
  padding: 10px 0;
  float: left;
  clear: both;
}

dl dd {
  padding: 10px 0;
}
</style>
